function popup() {
  const popup = document.getElementById('js-popup');
  const blackBg = document.getElementById('js-black-bg');
  const closeBtn = document.getElementById('js-close-btn');
  const modalContents = document.getElementById('js-modal-contents');
  const showBtn1 = document.getElementById('js-show-btn-1');
  const showBtn2 = document.getElementById('js-show-btn-2');
  const body = document.body;

  toggleModal(blackBg);
  toggleModal(closeBtn);
  toggleModal(showBtn1);
  toggleModal(showBtn2);

  // モーダルの開閉
  function toggleModal(e) {
    e.addEventListener('click', function click() {
      if (modalContents.firstChild) {
        modalContents.removeChild(modalContents.firstChild);
      }

      if (e.dataset.id) {
        const dataId = e.dataset.id;
        const url = `<iframe class="c-modal_movie" src="https://www.youtube.com/embed/${dataId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
        modalContents.insertAdjacentHTML('beforeend', url);
      }

      body.classList.toggle('is-hidden');
      popup.classList.toggle('is-show');
    });
  }
}

popup();
